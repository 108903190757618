<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body">
            <fieldset>
              <v-legend
                :title="'Requested Leaves'"
                :description="'Here are all the employees at your company, including terminated ones.'"
              ></v-legend>
              <div class="row mb-5">
                <div class="col-lg-3">
                  <select
                    @change="fetchLeaves()"
                    class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                    v-model="leave_type_id"
                  >
                    <option value="all">Leave Type --- All</option>
                    <option
                      v-for="(leaveTypeItem, index) in getActiveLeaveTypes"
                      :key="index"
                      :value="leaveTypeItem.id"
                    >
                      {{ leaveTypeItem.name }}
                    </option>
                  </select>
                </div>

                 <!-- <div class="col-lg-3">
                  <select
                    @change="fetchLeaves()"
                    class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                    v-model="leave_type_id"
                  >
                    <option value="all">Employee --- All</option>
                    <option
                      v-for="(leaveTypeItem, index) in getActiveLeaveTypes"
                      :key="index"
                      :value="leaveTypeItem.id"
                    >
                      {{ leaveTypeItem.name }}
                    </option>
                  </select>
                </div> -->

                 <div class="col-lg-3">
                  <select
                    @change="fetchLeaves()"
                    class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                    v-model="status"
                  >
                    <option value="all">Status--- All</option>
                   <option value="0">Pending</option>
                   <option value="1">Approved</option>
                   <option value="2">Cancelled</option>
                             <option value="3">Declined</option>
                  </select>
                </div>

        
              </div>

              <div class="v-data-table v-data-table--fixed-header theme--light">
                <div class="v-data-table__wrapper">
                  <table class="table table-hover">
                    <thead>
                      <tr style="background-color: #f2f4f5">
                        <th>Name</th>
                        <th width="80px">Type</th>
                        <th>Applied date</th>

                        <th>Requested Dates</th>
                        <th>Days</th>
                         <th>Is Paid</th>
                        <th>Status</th>
                        <th width="100px"></th>
                      </tr>
                      <tr v-if="loader">
                        <td colspan="12" class="text-center">
                          <data-loader
                            :type="'dark'"
                            :padding="10"
                          ></data-loader>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="12"
                          class="text-center"
                          v-if="getLeaves.length < 1 && !loader"
                        >
                          <div class="p-6">
                            <span>No Leave Request Found</span>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody v-if="getLeaves.length > 0 && !loader">
                      <tr
                        style="line-height: 40px"
                        v-for="(leaveItem, index) in getLeaves"
                        :key="index"
                      >
                        <td style="width:20%">
                          <div class="flex__row" id="user">
                             <img :src="leaveItem.user_avatar" alt="">
                             <div>
                              <span>
                                <b>{{ leaveItem.user_full_name }}</b>
                              </span>
                             
                             </div>
                          </div>
                        </td>
                        <td style="width: 20%">
                          <div class="leave-type">
                            <span
                              class="icon-circle"
                              :style="
                                'background-color:' +
                                leaveItem.leave_type.color_code
                              "
                            >
                              <i :class="leaveItem.leave_type.icon"></i
                            ></span>
                            <span>{{ leaveItem.leave_type.name }}</span>
                          </div>
                        </td>
                        <td style="width: 10%">
                          <div class="text-center css-1qw8bmg">
                            <div class="flex__row shrink-0">
                              <div class="flex__col">
                                <div
                                  class="calendar-card calendar-card--sm panel"
                                >
                                  <div class="calendar-card__day-month">{{leaveItem.applied_date_day}}</div>
                                  <div class="calendar-card__date">
                                   {{ leaveItem.applied_date_month_year }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td style="width: 20%">
                          <div class="text-center css-1qw8bmg">
                            <div class="flex__row shrink-0">
                              <div class="flex__col">
                                <div
                                  class="calendar-card calendar-card--sm panel"
                                >
                                  <div class="calendar-card__day-month">{{leaveItem.from_day}}</div>
                                  <div class="calendar-card__date">
                                   {{ leaveItem.from_month_year }}
                                  </div>
                                </div>
                              </div>
                              <div class="flex__col flex__col--flex">
                                <svg
                                  class="icon text-gray-light mx-1 icon-xxs"
                                  viewBox="0 0 24 24"
                                >
                                  <g>
                                    <path
                                      fill-rule="evenodd"
                                      d="M10.245 18.364l2.715 2.716L22 12.04 12.96 3l-2.715 2.716 4.403 4.403H2v3.841h12.648z"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                              <div class="flex__col">
                                <div
                                  class="calendar-card calendar-card--sm panel"
                                >
                                  <div class="calendar-card__day-month">{{leaveItem.to_day}}</div>
                                  <div class="calendar-card__date">
                                   {{ leaveItem.to_month_year }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        
                        <td style="width: 10%">{{ leaveItem.total_day }}</td>
                           <td style="width: 10%">
                             <i class="fa fa-times text-danger" v-if="leaveItem.status=='1' && leaveItem.is_paid=='0'"></i>
                              <!-- <i class="fa fa-circle text-default" v-if="leaveItem.status!='1'"></i> -->
                               <i class="fa fa-check text-success" v-if="leaveItem.status=='1' && leaveItem.is_paid=='1'" ></i>
                           </td>
                        <td>
                          <span
                            :class="leaveItem.status | leaveStatusClass"
                            class="badge"
                            >{{ leaveItem.status | leaveStatus }}</span
                          >
                        </td>
                        <td style="width: 10%">
                        
                          <a
                            @click="setDetails(leaveItem)"
                            class="btn btn-icon btn-light btn-sm ml-2 mr-2"
                            v-b-tooltip
                            title="view details"
                          >
                            <i class="fa fa-arrow-right text-dark text-sm"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#user{
  img{
    width: 30px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 50px;
    margin-right: 5px;
  }
}
.css-1qw8bmg {
  max-width: 102px;
}
.flex__row {
  display: flex;
  align-items: center;
  min-width: 0;
}
.flex__col--flex {
  display: flex;
}

svg:not(:root) {
  overflow: hidden;
}
.icon-xxs {
  width: 12px;
  height: 12px;
}
.icon {
  vertical-align: middle;
  flex-shrink: 0;
  transition: 125ms ease-out;
  transition-property: fill, stroke;
}
.shrink-0 {
  flex-shrink: 0;
}

.panel {
  overflow: hidden;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  border-radius: 5px;
}

.calendar-card--sm {
  width: 60px;
  height: 40px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.calendar-card {
  text-align: center;
  color: #333;
}

.calendar-card__date {
  font-size: 8px;
  text-transform: uppercase;
  color: #747474;
}

.calendar-card__day-month {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: -15px;
  margin-top: 15px;
}

.leave-type {
  display: inline-flex;
  .icon-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50px;
    color: white;
    margin-right: 10px;
    margin-top: 2px;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>

<script>
import {
  SET_BREADCRUMB,
  SET_EMPLOYEE_LEAVE_DETAILS,
} from "@/core/services/store/mutations.type";
import Legend from "@/includes/content/widgets/Legend";
import {
  FETCH_LEAVES,
  FETCH_ACTIVE_LEAVE_TYPE,
} from "@/core/services/store/actions.type";
import dataLoader from "@/includes/content/DataLoader";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loader: false,
   
  
      leave_type_id: "all",
      status: "all",
    };
  },
  components: {
    "data-loader": dataLoader,
    "v-legend": Legend,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Requested Leaves" }]);
    this.$store.dispatch(FETCH_ACTIVE_LEAVE_TYPE);
    this.fetchLeaves();
  },
  methods: {
    fetchLeaves() {
      this.loader = true;
      this.$store
        .dispatch(FETCH_LEAVES, {
            leave_type_id: this.leave_type_id,
            status: this.status
        })
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setDetails(data) {
      this.$store.commit(SET_EMPLOYEE_LEAVE_DETAILS, data);
      this.$router.push({
        name: "requested.leave.employee.details",
        params: { id: data.id },
      });
    },
  },
  computed: {
    ...mapGetters(["getLeaves", "getActiveLeaveTypes"]),
  },
  filters: {
    leaveStatus(status) {
      var str = "";
      switch (status) {
        case "0":
          str = "Pending";
          break;
        case "1":
          str = "Accepted";
          break;
        case "2":
          str = "Declined";
          break;
        case "3":
          str = "Canceled";
          break;
      }
      return str;
    },
    leaveStatusClass(status) {
      var class_ = "";
      switch (status) {
        case "0":
          class_ = "badge-info";
          break;
        case "1":
          class_ = "badge-success";
          break;
        case "2":
          class_ = "badge-danger";
          break;
        case "3":
          class_ = "badge-secondary";
          break;
      }
      return class_;
    },
  },
};
</script>
